import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as EmailValidator from 'email-validator';
import Loading from './../../../common/alert/Loading';
import { createAdmin } from './../../../redux/actions/authAction';

const Createuser = () => {
  const { alert, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  //
  return (
    <Formik
      initialValues={{
        fullname: '',
        email: '',
        password: '',
      }}
      onSubmit={(values, { setSubmitting, setValues }) => {
        setTimeout(async () => {
          dispatch(createAdmin(values));

          setSubmitting(false);
        }, 500);

        setTimeout(() => {
          // set input to empty
          const data = {
            fullname: '',
            email: '',
            password: '',
          };
          setValues(data);
        }, 2000);
      }}
      //   HANDLING VALIDATION MESSAGES
      validate={(values) => {
        let errors = {};

        if (!values.fullname) {
          errors.fullname = 'Name is required';
        }

        // EMAIL SECTION
        if (!values.email) {
          errors.email = 'Email is required';
        } else if (!EmailValidator.validate(values.email)) {
          errors.email = 'Invalid email address';
        }

        //   THE PASSWORD SECTION
        if (!values.password) {
          errors.password = 'Password is required';
        }

        return errors;
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div id='overflow' className='create-user'>
            <div className='create-form'>
              <h1>Create Admin</h1>
              <small>Easy way to find a perfect home</small>

              <form onSubmit={handleSubmit}>
                <div className='form_group'>
                  <label htmlFor='fullname'>Full name</label>
                  <input
                    name='fullname'
                    type='text'
                    id='fullname'
                    placeholder='Bright steven'
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.fullname && touched.fullname && (
                    <div className='input_feedback'>{errors.fullname}</div>
                  )}
                </div>

                <div className='form_group'>
                  <label htmlFor='email'>Email</label>
                  <input
                    name='email'
                    type='text'
                    placeholder='bright@example.com'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // className={errors.email && touched.email && 'error'}
                  />
                  {errors.email && touched.email && (
                    <div className='input_feedback'>{errors.email}</div>
                  )}
                </div>

                <div className='form_group'>
                  <label htmlFor='password'>Password</label>
                  <input
                    name='password'
                    type='password'
                    placeholder='Enter your password'
                    value={values.password}
                    autoComplete='on'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.password && touched.password && 'error'}
                  />
                  {errors.password && touched.password && (
                    <div className='input_feedback'>{errors.password}</div>
                  )}

                  <Link to='/auth/forgot-password'>
                    <div className='password-forgot'>forgot password?</div>
                  </Link>
                </div>

                <div className='form_group'>
                  <button type='submit' disabled={isSubmitting}>
                    {alert.authloading ? (
                      <Loading width='25px' height='25px' color='#fff' />
                    ) : (
                      'Create user'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Createuser;
