import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../common/Layout';
import AllAdverts from './../components/all_adverts/AllAdverts';
import { get_adverts } from './../redux/actions/advertsAction';

// COMPONENTS

//

const Adverts = () => {
  const { toggle } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_adverts());
  }, [dispatch]);

  //
  return (
    <Layout>
      <div id={toggle ? 'response' : 'responsive'}>
        <AllAdverts />
      </div>
    </Layout>
  );
};

export default Adverts;
