import { combineReducers } from 'redux';

import utils from './utilsReducer';
import alert from './alertReducer';
import listing from './listingReducer';
import user from './userReducer';
import auth from './authReducer';
import advert from './advertReducer';
import loading from './loadingReducer';

// =================
export default combineReducers({
  utils,
  alert,
  listing,
  user,
  auth,
  advert,
  loading,
});
