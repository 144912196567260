import {
  // deleteDataAPIS,
  getData,
  getDataAPI,
  postDataAPIS,
} from "../../utils/fetchData";
import { GLOBALTYPES } from "./globalTypes";

// Get All Users
export const get_users = () => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { userloading: true } });
    const res = await getData("/all-users");

    dispatch({
      type: GLOBALTYPES.ALL_USERS,
      payload: res.data,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: {} });
  } catch (error) {
    //
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: error.response.data.error,
      },
    });

    dispatch({ type: GLOBALTYPES.ALERT, payload: {} });
  }
};

// Get All Agents
export const get_agents = () => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { agentloading: true } });
    const res = await getData("/all_agents");

    dispatch({
      type: GLOBALTYPES.ALL_AGENTS,
      payload: res.data,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { agentloading: false} });
  } catch (error) {
    //
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: error.response.data.error,
      },
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { agentloading: false} });
  }
};


// Create Categories
export const create_categories =
  (data, token, callback) => async (dispatch) => {
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { categoryloading: true } });
      const res = await postDataAPIS("/categories/categories", data, token);
      console.log(res);

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          success: res.data.message,
        },
      });

      setTimeout(() => {
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { categoryloading: false },
        });
        dispatch({ type: GLOBALTYPES.ALERT, payload: {} });
        dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
        dispatch({ type: GLOBALTYPES.CATEGORY_MODAL, payload: false });
      }, 6000);
    } catch (error) {
      //
      console.log(error.response);
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: error.response.data.error,
        },
      });
    }
  };

// Get Sub Categories
export const subCategories = (categoryid) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { productloading: true } });

    const res = await getDataAPI(`/subcategories/all/${categoryid}`);

    dispatch({
      type: GLOBALTYPES.SUB_CATEGORIES,
      payload: res.data.data,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { productloading: false } });
  } catch (error) {
    //
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: error.response,
      },
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { productloading: false } });
  }
};

