import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Loading from '../../common/alert/Loading';
import Filter from '../../common/filter/Filter';
import Pagination from '../../common/filter/Pagination';
import { filteringMethod } from '../../utils/utils';
import placeholder from '../../assets/placeholder.png';
import { Modal } from 'react-bootstrap';
import SuspendModal from './../../common/suspendmodal/SuspendModal';

//

const AllAgent = () => {
  const { userloading } = useSelector((state) => state.alert);
  const { all_users } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState('');
  const [values, setValues] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [suspendModal, setSuspendModal] = useState(false);
  const [agentId, setAgentId] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);
  }, []);

  let sorted = filteringMethod(all_users, data, values);

  // get all registered agents alone from users
  const agents = sorted.filter((item) => item.userType === 'agent');

  // Pagination function
  let page;
  let currentData = useMemo(() => {
    let firstPageIndex = (currentPage - 1) * pageSize;
    let lastPageIndex = firstPageIndex + pageSize;
    /* eslint-disable */
    page = firstPageIndex;
    return agents.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, agents]);

  //get the item counts
  const count = page + currentData.length;

  // handleSuspend
  const handleSuspend = (id) => {
    setAgentId(id);
    setSuspendModal(true);
  };

  if (userloading) {
    return (
      <div className='listing-loading'>
        <Loading width='40px' height='40px' color='#A1257D' />
      </div>
    );
  }

  //
  return (
    <div className='agents'>
      {/* display the filter component if current data is not null */}
      {currentData !== null && (
        <Filter
          data={data}
          setData={setData}
          values={values}
          setValues={setValues}
        />
      )}

      <div className='agents-table'>
        {/* display showing the length of items if current data is not null */}
        {currentData !== null && (
          <small className='showing'>
            Showing <b>{count}</b> users of <b>{all_users?.length}</b> total
          </small>
        )}

        {currentData === null ? (
          <p className='text-center mt-4'>No user found</p>
        ) : (
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>No.</th>
                <th scope='col'>Image</th>
                <th scope='col'>Fullname</th>
                <th scope='col'>Email</th>
                <th scope='col'>Username</th>
                <th scope='col'>Verified</th>
                <th scope='col'>Suspended</th>
                <th scope='col'>Joined</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>

            <tbody>
              {currentData.map((item, index) => {
                const {
                  _id,
                  image,
                  fullname,
                  email,
                  username,
                  verification,
                  isSuspended,
                  createdAt,
                } = item;

                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>
                      <div className='table-image'>
                        <img
                          src={image === null ? placeholder : image}
                          alt='agents-pic'
                        />
                      </div>
                    </td>
                    <td>{fullname}</td>
                    <td>{email}</td>
                    <td style={{ textTransform: 'capitalize' }}>{username}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {verification?.length === 0
                        ? 'Not verified'
                        : verification[0]?.isVerified}
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {isSuspended ? 'true' : 'false'}
                    </td>
                    <td>{moment(createdAt).format('MMMM Do YYYY')}</td>
                    <td>
                      <div className='table-actions'>
                        <NavLink to={`/users/agent-details/${_id}`}>
                          <button className='view'>view</button>
                        </NavLink>
                        <button
                          className='delete'
                          onClick={() => handleSuspend(_id)}
                        >
                          Suspend
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      {currentData !== null && (
        <Pagination
          filteringData={all_users}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {/* suspend modal */}
      <Modal
        show={suspendModal}
        onHide={() => {
          setSuspendModal(false);
        }}
        dialogClassName='suspend-modal'
      >
        <SuspendModal
          token={token}
          setSuspendModal={setSuspendModal}
          id={agentId}
        />
      </Modal>
    </div>
  );
};

export default AllAgent;
