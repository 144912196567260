import { useState } from 'react';
import cogoToast from 'cogo-toast';
import { useDispatch, useSelector } from 'react-redux';
import { patchDataAPI } from '../../utils/fetchData';
import { useParams } from 'react-router-dom';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';

const initialState = {
  reason_one: '',
  reason_two: '',
  reason_three: '',
};

//

const DeclineListing = ({ token, setDeclineModal }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { callback } = useSelector((state) => state.utils);
  const { id } = useParams();
  const [values, setValues] = useState(initialState);

  // handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const declineAgent = async (e) => {
    e.preventDefault();

    const newData = {
      id: id,
      one: values.reason_one,
      two: values.reason_two,
      three: values.reason_three,
    };

    try {
      setLoading(true);
      const res = await patchDataAPI('/decline-listing', newData, token);

      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      setLoading(false);
      setDeclineModal(false);
    } catch (error) {
      console.log(error?.response);
      cogoToast.error(error?.response?.data?.msg);
      setLoading(false);
    }
  };
  //

  return (
    <div className='decline-box'>
      <div className='text-center'>
        <h1>Give reasons for declining</h1>
      </div>

      {/* Profile input section */}
      <div className='decline-form'>
        <div className='col-12 mb-4'>
          <label className='mb-1'>Reason One</label>
          <textarea
            autoComplete='off'
            type='text'
            className='form-control'
            placeholder='Write something'
            value={values.reason_one}
            onChange={handleChange}
            name='reason_one'
          />
        </div>

        <div className='col-12 mb-4'>
          <label className='mb-1'>Reason Two</label>
          <textarea
            autoComplete='off'
            type='text'
            className='form-control'
            placeholder='Write something'
            value={values.reason_two}
            onChange={handleChange}
            name='reason_two'
          />
        </div>

        <div className='col-12 mb-4'>
          <label className='mb-1'>Reason Three</label>
          <textarea
            autoComplete='off'
            type='text'
            className='form-control'
            placeholder='Write something'
            value={values.reason_three}
            onChange={handleChange}
            name='reason_three'
          />
        </div>

        <button
          className='btn save'
          onClick={declineAgent}
          // disabled={
          //   !values.reason_one || !values.reason_two || !values.reason_three
          //     ? true
          //     : false
          // }
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default DeclineListing;
