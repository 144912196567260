import React from 'react';
import { Route, Routes } from 'react-router-dom';
//
import Overview from './../pages/Overview';
import Login from './../pages/Login';
import ProductDetails from './../pages/ProductDetails';
import CreateCategories from './../pages/CreateCategories';
import AllCategories from './../pages/AllCategories';
import SubCategories from './../pages/SubCategories';
import Users from './../pages/Users';
import Agents from '../pages/users/Agents';
import AgentDetails from '../pages/users/AgentDetails';
import AllListings from '../pages/AllListings';
import ListingDetails from '../pages/ListingDetails';
import CreateUser from './../pages/users/CreateUser';
import ReportedListings from './../pages/ReportedListings';
import ReportingDetails from './../pages/ReportingDetails';
import Adverts from './../pages/Adverts';

//

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />

      {/* Dashboard section */}
      <Route path='/dashboard/overview' element={<Overview />} />

      {/* Users section */}
      <Route path='/users/create-user' element={<CreateUser />} />
      <Route path='/users/all-users' element={<Users />} />
      <Route path='/users/all-agents' element={<Agents />} />
      <Route path='/users/agent-details/:id' element={<AgentDetails />} />

      {/* Listings section */}
      <Route path='/listings/all-listings' element={<AllListings />} />
      <Route path='/listings/listingdetails/:id' element={<ListingDetails />} />

      <Route
        path='/listings/reported-listings'
        element={<ReportedListings />}
      />
      <Route
        path='/listings/reportedlistingdetails/:id'
        element={<ReportingDetails />}
      />

      <Route
        path='product/all-products/:productsid'
        element={<ProductDetails />}
      />
      <Route path='product/create-categories' element={<CreateCategories />} />
      <Route path='product/all-categories' element={<AllCategories />} />
      <Route
        path='product/all-categories/:categoryid'
        element={<SubCategories />}
      />

      {/* Adverts section */}
      <Route path='/advertise/all-adverts' element={<Adverts />} />
      {/* </Route> */}
    </Routes>
  );
};

export default Router;
