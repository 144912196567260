import React, { useState } from 'react';
import Loading from './../alert/Loading';
import { patchDataAPI, patchDataAPIS } from './../../utils/fetchData';
import cogoToast from 'cogo-toast';
import { GLOBALTYPES } from './../../redux/actions/globalTypes';
import { useSelector, useDispatch } from 'react-redux';

const SuspendModal = ({ token, setSuspendModal, id }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { callback } = useSelector((state) => state.utils);

  const suspendAccount = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const newData = {
        id: id,
      };

      const res = await patchDataAPI('/suspend-agent', newData, token);

      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      setLoading(false);
      setSuspendModal(false);
    } catch (error) {
      setLoading(false);
      cogoToast.error(error?.response?.data?.msg, { hideAfter: 5 });
    }
  };

  return (
    <div className='personal-infor'>
      <div className='row'>
        <div className='col-9'>
          <h6>Suspend Agent?</h6>
          <p className='mt-3'>
            Agent will be temporarily suspended if you proceed!
          </p>
        </div>

        <div className='col-3'>
          <div className='address-cancel float-end'>
            <i
              className='bi bi-x-circle'
              onClick={() => {
                setSuspendModal(false);
              }}
            />
          </div>
        </div>
      </div>

      <hr />

      {/* Profile input section */}
      <div className='form-boxs'>
        <div className='delete-box'>
          <button className='close' onClick={() => setSuspendModal(false)}>
            Close
          </button>

          <button className='delete' onClick={suspendAccount}>
            {loading ? (
              <Loading height='20px' width='20px' color='#fff' />
            ) : (
              'Suspend'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuspendModal;
