import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

//
import Loading from '../../common/alert/Loading';
import Filter from '../../common/filter/Filter';
import Pagination from '../../common/filter/Pagination';
import { filteringMethod } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

//

const AllAdverts = () => {
  const { loading } = useSelector((state) => state);
  const { all_adverts } = useSelector((state) => state.advert);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState('');
  const [values, setValues] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  let sorted = filteringMethod(all_adverts, data, values);

  // Pagination function
  let page;
  let currentData = useMemo(() => {
    let firstPageIndex = (currentPage - 1) * pageSize;
    let lastPageIndex = firstPageIndex + pageSize;
    /* eslint-disable */
    page = firstPageIndex;
    return sorted.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, sorted]);

  //
  const count = page + currentData.length;

  if (loading.advertloading) {
    return (
      <div className='page-loading'>
        <Loading width='45px' height='45px' color='#A1257D' />
      </div>
    );
  }

  //
  return (
    <div className='advert'>
      {/* display the filter component if current data is not null */}

      <Filter
        data={data}
        setData={setData}
        values={values}
        setValues={setValues}
      />

      <div className='advert-table'>
        {(currentData === null || currentData?.length === 0) &&
        !loading.advertloading ? (
          <p className='text-center mt-4'>No Adverts found</p>
        ) : (
          <>
            <small className='showing'>
              Showing <b>{count}</b> listings of <b>{all_adverts?.length}</b>{' '}
              total
            </small>

            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'>No.</th>
                  <th scope='col' style={{ width: '7%' }}>
                    Image
                  </th>
                  <th scope='col'>Fullname</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Website</th>
                  <th scope='col'>Pricing</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Active</th>
                  <th scope='col'>Started</th>
                  <th scope='col'>Ending</th>
                  <th scope='col'>Actions</th>
                </tr>
              </thead>

              <tbody>
                {currentData.map((item, index) => {
                  const {
                    _id,
                    fullname,
                    email,
                    website,
                    pricing,
                    image,
                    status,
                    isActive,
                    isStarted,
                    isEnded,
                  } = item;

                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>
                        <div className='table-image'>
                          <img src={image.url} alt='advert-pic' />
                        </div>
                      </td>
                      <td>{fullname}</td>
                      <td>{email}</td>
                      <td>{website}</td>
                      <td>{pricing}</td>
                      <td>{status}</td>
                      <td>{isActive ? 'Active' : 'InActive'}</td>

                      <td>{moment(isStarted).format('MMMM Do YYYY')}</td>
                      <td>{moment(isEnded).format('MMMM Do YYYY')}</td>
                      <td>
                        <div className='table-actions'>
                          <button
                            className='view'
                            onClick={() =>
                              navigate(`/listings/listingdetails/${_id}`)
                            }
                          >
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>

      {currentData !== null &&
        !loading.advertloading &&
        currentData?.length !== 0 && (
          <Pagination
            filteringData={all_adverts}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
    </div>
  );
};

export default AllAdverts;
