import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../common/Layout';

// COMPONENTS
import DashboardOverview from './../components/overview/DashboardOverview';
import { getDataAPI } from './../utils/fetchData';

//

const Overview = () => {
  const { toggle } = useSelector((state) => state.utils);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const getLoggedInUser = async () => {
      try {
        const res = await getDataAPI('/admin-user', token);
        localStorage.setItem('user', JSON.stringify(res.data));
        // console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getLoggedInUser();
  }, []);

  //
  return (
    <Layout>
      <div id={toggle ? 'response' : 'responsive'}>
        <DashboardOverview />
      </div>
    </Layout>
  );
};

export default Overview;
