import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../common/Layout";
import AgentDetail from "../../components/agentdetail/AgentDetail";

// COMPONENTS

//

const AgentDetails = () => {
  const { toggle } = useSelector((state) => state.utils);

  //
  return (
    <Layout>
      <div id={toggle ? "response" : "responsive"}>
        <AgentDetail />
      </div>
    </Layout>
  );
};

export default AgentDetails;
