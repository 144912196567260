import { postDataAPI } from "./../../utils/fetchData";
import { GLOBALTYPES } from "./globalTypes";
import cogoToast from "cogo-toast";

//
export const createAdmin = (data) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { authloading: true } });

    const res = await postDataAPI("/create-admin", data);
    console.log(res.data);
    cogoToast.success(res.data.message);
    dispatch({ type: GLOBALTYPES.ALERT, payload: { authloading: false } });
  } catch (err) {
    cogoToast.error(err.response.data.msg);
    dispatch({ type: GLOBALTYPES.ALERT, payload: { authloading: false } });
  }
};

export const login = (data, navigate) => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { authloading: true } });

    const res = await postDataAPI("/login-admin", data);

    dispatch({
      type: GLOBALTYPES.AUTH,
      payload: { token: res.data.access_token },
    });

    localStorage.setItem("token", res.data.access_token);

    cogoToast.success(res.data.msg);
    navigate("/dashboard/overview");
    dispatch({ type: GLOBALTYPES.ALERT, payload: { authloading: false } });
  } catch (err) {
    cogoToast.error(err.response.data.msg);
    dispatch({ type: GLOBALTYPES.ALERT, payload: { authloading: false } });
  }
};
