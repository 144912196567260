import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../common/Layout';
import ReportingDetail from './../components/reportingdetails/ReportingDetail';

// COMPONENTS

//

const ReportingDetails = () => {
  const { toggle } = useSelector((state) => state.utils);

  //
  return (
    <Layout>
      <div id={toggle ? 'response' : 'responsive'}>
        <ReportingDetail />
      </div>
    </Layout>
  );
};

export default ReportingDetails;
