import { getData } from '../../utils/fetchData';
import { GLOBALTYPES } from './globalTypes';

// ALL LISTINGS
export const all_listings = () => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { listingloading: true } });
    const res = await getData('/all_listing');

    dispatch({
      type: GLOBALTYPES.ALL_LISTINGS,
      payload: res.data,
    });
    dispatch({ type: GLOBALTYPES.ALERT, payload: { listingloading: false } });
  } catch (error) {
    //
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: error.response.data.error,
      },
    });
    // dispatch({ type: GLOBALTYPES.ALERT, payload: { listingloading: false } });
  }
};
