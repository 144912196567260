import React from "react";
//

const Filter = ({ data, setData, values, setValues }) => {
  //   The search handleChange
  const handleChange = (e) => {
    setData(e.target.value);
  };

  // The sort handle sort
  const handleSort = (e) => {
    setValues(e.target.value);
  };

  //

  return (
    <div className="filtering">
      <input
        type="text"
        onChange={handleChange}
        value={data}
        placeholder="Search products"
      />

      <select onChange={handleSort} value={values}>
        <option disabled defaultValue="Sort by">
          Sort by
        </option>
        <option value="1">Newest</option>
        <option value="2">Oldest</option>
      </select>
    </div>
  );
};

export default Filter;
