import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { get_users } from './../../redux/actions/userAction';
import placeholder from '../../assets/placeholder.png';
import { patchDataAPI } from '../../utils/fetchData';
import cogoToast from 'cogo-toast';
import { GLOBALTYPES } from './../../redux/actions/globalTypes';

//

const ReportedUserModal = ({ id, token }) => {
  const { all_users } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { callback } = useSelector((state) => state.utils);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(get_users());

    if (all_users) {
      all_users.forEach((item) => {
        if (item._id === id) {
          setUser(item);
        }
      });
    }
  }, [dispatch]);

  // Suspend user account
  const suspendAccount = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const newData = {
        id: id,
      };

      const res = await patchDataAPI('/suspend-agent', newData, token);

      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      cogoToast.error(error?.response?.data?.msg, { hideAfter: 5 });
    }
  };

  //

  return (
    <div className='container user-reported'>
      <div className='row'>
        <h1>Listing Reported User</h1>

        <div className='col-5'>
          <div className='image-box'>
            <img src={!user?.image ? placeholder : user?.image} alt='' />
          </div>
        </div>

        <div className='col-7'>
          <h2>{user?.fullname}</h2>
          <h4>@{user?.username}</h4>
          <h3>{user?.email}</h3>
          <p>{user?.userType}</p>

          <button onClick={suspendAccount}>
            {loading ? 'Suspending' : 'Suspend user'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportedUserModal;
