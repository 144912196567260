import { GLOBALTYPES } from '../actions/globalTypes';

const initialState = {
  all_adverts: [],
};

const advertReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBALTYPES.ALL_ADVERTS:
      return {
        ...state,
        all_adverts: payload,
      };

    default:
      return state;
  }
};

export default advertReducer;
