import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

//
import Loading from '../../common/alert/Loading';
import Goback from '../../common/goback/Goback';
import { useParams, useNavigate } from 'react-router-dom';
import { GLOBALTYPES } from './../../redux/actions/globalTypes';
import cogoToast from 'cogo-toast';
import { patchDataAPI, deleteDataAPIS } from '../../utils/fetchData';
import { Modal } from 'react-bootstrap';
import DeclineListing from './../../common/declinemodal/DeclineListing';
import placeholder from '../../assets/placeholder.png';

//

const ListingDetail = () => {
  const dispatch = useDispatch();
  const { listingloading } = useSelector((state) => state.alert);
  const { all_listings } = useSelector((state) => state.listing);
  const { callback } = useSelector((state) => state.utils);
  const { id } = useParams();
  const [detail, setDetail] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [declineModal, setDeclineModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);

    if (id) {
      const res = all_listings.find((item) => item._id === id);
      setDetail(res);
    }
  }, [id, all_listings]);

  // handleApprove
  const approveListing = async (e) => {
    e.preventDefault();

    const newData = {
      id: 'new data',
    };

    try {
      setApproveLoading(true);
      const res = await patchDataAPI(`/approve-listing/${id}`, newData, token);
      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      setApproveLoading(false);
    } catch (error) {
      console.log(error);
      cogoToast.error(error.response.data.msg);
      setApproveLoading(false);
    }
  };

  // delete listing
  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      setDeleteLoading(true);
      const res = await deleteDataAPIS(`/admin-delete-listing/${id}`, token);

      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      navigate('/listings/all-listings');
      setDeleteLoading(false);
    } catch (error) {
      console.log(error);
      cogoToast.error(error.response.data.msg);
      setDeleteLoading(false);
    }
  };

  // open decline modal
  const handleDecline = () => {
    setDeclineModal(true);
  };

  if (listingloading) {
    return (
      <div className='listing-loading'>
        <Loading height='50px' width='50px' />
      </div>
    );
  }

  //
  return (
    <div className='listing-details'>
      <div className='container-fluid'>
        <div className='header-section'>
          <div className='row'>
            <div className='col profile-section'>
              <div className='profile'>
                <img
                  src={
                    detail?.postedBy?.image === null
                      ? placeholder
                      : detail?.postedBy?.image
                  }
                  alt=''
                />
              </div>

              <div>
                <h1>{detail?.postedBy?.fullname}</h1>
                <h2>@{detail?.postedBy?.username}</h2>
                <p>{detail?.postedBy?.email}</p>
                <small>{detail?.status}</small>
              </div>
            </div>

            <div className='col-5 '>
              <div className='d-flex align-items-center justify-content-end'>
                {detail?.status !== 'verified' &&
                  detail?.status !== 'declined' && (
                    <>
                      <button className='approve' onClick={approveListing}>
                        {approveLoading ? 'Approving' : 'Approve'}
                      </button>

                      <button className='decline' onClick={handleDecline}>
                        Decline
                      </button>
                    </>
                  )}

                {/* <button className='decline' onClick={handleDecline}>
                  Decline
                </button> */}

                {detail?.status === 'declined' && (
                  <button className='approve' onClick={approveListing}>
                    Re-Approve
                  </button>
                )}

                <button className='delete' onClick={handleDelete}>
                  {deleteLoading ? 'Deleting' : 'Delete'}
                </button>
              </div>

              <div className='d-flex align-items-end justify-content-end h-100'>
                <p>
                  Property updated :{' '}
                  {moment(detail?.updatedAt).format('MMMM Do YYYY')}{' '}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='middle-section'>
          <h3>Property Information</h3>

          <div className='row'>
            <div className='col'>
              <label htmlFor=''>Property type</label>
              <input
                type='text'
                className='form-control'
                placeholder='property_type'
                aria-label='property_type'
                value={detail?.property_type}
                readOnly={true}
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Price</label>
              <input
                type='text'
                className='form-control'
                placeholder='price'
                aria-label='price'
                value={detail?.price}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>State name</label>
              <input
                type='text'
                className='form-control'
                placeholder='state_name'
                aria-label='state_name'
                value={detail?.statename}
                readOnly
              />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <label htmlFor=''>City name</label>
              <input
                type='text'
                className='form-control'
                placeholder='city_name'
                aria-label='city_name'
                value={detail?.cityname}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Status</label>
              <input
                type='text'
                className='form-control'
                placeholder='status'
                aria-label='status'
                value={detail?.status}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Category</label>
              <input
                type='text'
                className='form-control'
                placeholder='category'
                aria-label='category'
                value={detail?.category}
                readOnly
              />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <label htmlFor=''>Toilets</label>
              <input
                type='text'
                className='form-control'
                placeholder='toilets'
                aria-label='toilets'
                value={detail?.toilets}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Bathrooms</label>
              <input
                type='text'
                className='form-control'
                placeholder='bathrooms'
                aria-label='bathrooms'
                value={detail?.bathrooms}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Bedrooms</label>
              <input
                type='text'
                className='form-control'
                placeholder='bedrooms'
                aria-label='bedrooms'
                value={detail?.bedrooms}
                readOnly
              />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <label htmlFor=''>Address</label>
              <input
                type='text'
                className='form-control'
                placeholder='address'
                aria-label='address'
                value={detail?.address}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Acquired</label>
              <input
                type='text'
                className='form-control'
                placeholder='acquired'
                aria-label='acquired'
                value={detail?.acquired}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Furnishing</label>
              <input
                type='text'
                className='form-control'
                placeholder='furnishing'
                aria-label='furnishing'
                value={detail?.furnishing}
                readOnly
              />
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <label htmlFor=''>Area Facilities</label>
              <select id='inputState' className='form-select' readOnly>
                {detail?.area_facilities?.map((facility, index) => (
                  <option value={facility} key={index}>
                    {facility}
                  </option>
                ))}
              </select>
            </div>

            <div className='col'>
              <label htmlFor=''>Country</label>
              <input
                type='text'
                className='form-control'
                placeholder='acquired'
                aria-label='acquired'
                value={detail?.country}
                readOnly
              />
            </div>

            <div className='col'>
              <label htmlFor=''>Home facilities</label>
              <select id='inputState' className='form-select'>
                {detail?.home_facilities?.map((facility, index) => (
                  <option value={facility} key={index}>
                    {facility}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button>Save</button>
          </div>
        </div>

        <div className='image-section'>
          {detail?.images?.map((image, index) => (
            <div className='image-card' key={index}>
              <img src={image.url} alt='' key={image.id} />
            </div>
          ))}
        </div>
      </div>

      <Modal
        show={declineModal}
        onHide={() => {
          setDeclineModal(false);
        }}
        dialogClassName='decline-modal'
      >
        <DeclineListing token={token} setDeclineModal={setDeclineModal} />
      </Modal>
    </div>
  );
};

export default ListingDetail;
