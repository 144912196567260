import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_users } from "../redux/actions/userAction";
import AllListing from "./../components/all_listings/AllListing";
import { all_listings } from "./../redux/actions/listingsAction";
import Layout from "./../common/Layout";

// COMPONENTS

//

const AllListings = () => {
  const { toggle, callback } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(all_listings());
  }, [dispatch, callback]);

  //
  return (
    <Layout>
      <div id={toggle ? "response" : "responsive"}>
        <AllListing />
      </div>
    </Layout>
  );
};

export default AllListings;
