import {
  // deleteDataAPIS,
  getData,
  getDataAPI,
  postDataAPIS,
} from '../../utils/fetchData';
import { GLOBALTYPES } from './globalTypes';

// Get All Users
export const get_adverts = () => async (dispatch) => {
  try {
    dispatch({ type: GLOBALTYPES.LOADING, payload: { advertloading: true } });
    const res = await getData('/get-advert');

    dispatch({
      type: GLOBALTYPES.ALL_ADVERTS,
      payload: res.data,
    });

    dispatch({ type: GLOBALTYPES.LOADING, payload: { advertloading: false } });
  } catch (error) {
    //

    dispatch({ type: GLOBALTYPES.LOADING, payload: { advertloading: false } });
  }
};

// Create Categories
export const create_categories =
  (data, token, callback) => async (dispatch) => {
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { categoryloading: true } });
      const res = await postDataAPIS('/categories/categories', data, token);
      console.log(res);

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          success: res.data.message,
        },
      });

      setTimeout(() => {
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { categoryloading: false },
        });
        dispatch({ type: GLOBALTYPES.ALERT, payload: {} });
        dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
        dispatch({ type: GLOBALTYPES.CATEGORY_MODAL, payload: false });
      }, 6000);
    } catch (error) {
      //
      console.log(error.response);
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: error.response.data.error,
        },
      });
    }
  };
