import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiMenu } from 'react-icons/fi';
//
import { GLOBALTYPES } from './../../redux/actions/globalTypes';
import Goback from '../../common/goback/Goback';
import placeholder from '../../assets/placeholder.png';

const Topbar = () => {
  const { toggle } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));

  //
  return (
    <div className='top'>
      <div className={toggle ? 'topbars' : 'topbar'}>
        <div className='topbar-icon'>
          {/* <MdOutlineMenuOpen /> */}

          <FiMenu
            onClick={() =>
              dispatch({ type: GLOBALTYPES.TOGGLE, payload: !toggle })
            }
            className='toggle-icon'
            style={{ marginRight: '25px' }}
          />

          <Goback />
        </div>

        <div className='topbar-profile'>
          <div className='topbar-name'>
            <small>{user?.fullname}</small>
            <span>{user?.email}</span>
          </div>

          <div className='topbar-image'>
            <img src={placeholder} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
