import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { all_listings } from '../redux/actions/listingsAction';
import ListingDetail from '../components/listingdetails/ListingDetail';
import Layout from './../common/Layout';

// COMPONENTS

//

const ListingDetails = () => {
  const { toggle, callback } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(all_listings());
  }, [dispatch, callback]);

  //
  return (
    <Layout>
      <div id={toggle ? 'response' : 'responsive'}>
        <ListingDetail />
      </div>
    </Layout>
  );
};

export default ListingDetails;
