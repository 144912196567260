export const GLOBALTYPES = {
  TOGGLE: 'TOGGLE',
  ALERT: 'ALERT',
  AUTH: 'AUTH',
  ALL_USERS: 'ALL_USERS',
  ALL_AGENTS: 'ALL_AGENTS',
  ALL_LISTINGS: 'ALL_LISTINGS',
  LOADING: 'LOADING',
  CALLBACK: 'CALLBACK',
  ALL_ADVERTS: 'ALL_ADVERTS',
};
