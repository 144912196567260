import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import ScrollToTop from "./common/ScrollToTop";
import Alert from "./common/alert/Alert";
import Layout from './common/Layout';

function App() {
  //
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Alert />
        <Router />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
