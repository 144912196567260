import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { all_listings } from '../redux/actions/listingsAction';
import Layout from '../common/Layout';
import ReportedListing from './../components/reported_listings/ReportedListing';

// COMPONENTS

//

const ReportedListings = () => {
  const { toggle, callback } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(all_listings());
  }, [dispatch, callback]);

  //
  return (
    <Layout>
      <div id={toggle ? 'response' : 'responsive'}>
        <ReportedListing />
      </div>
    </Layout>
  );
};

export default ReportedListings;
