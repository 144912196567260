import React from "react";

//
import Paginate from "../pagination/Paginate";

//

const Filter = ({
  filteringData,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
}) => {
  //

  return (
    <div className="filter">
      <div className="page-size">
        <div className="col">
          <select
            id="inputState"
            className="form-select"
            onChange={(e) => setPageSize(parseInt(e.target.value))}
          >
            <option disabled defaultValue="Page Size">
              Page size
            </option>
            <option value="10">show items: 10</option>
            <option value="20">show items: 20</option>
            <option value="30">show items: 30</option>
            <option value="40">show items: 40</option>
            <option value="50">show items: 50</option>
            <option value="60">show items: 60</option>
            <option value="70">show items: 70</option>
            <option value="80">show items: 80</option>
            <option value="90">show items: 90</option>
            <option value="100">show items: 100</option>
          </select>
        </div>
      </div>

      <div className="paginating">
        <Paginate
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filteringData.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default Filter;
