import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../common/Layout";
import AllUser from "../components/allusers/AllUser";
import { get_users } from "../redux/actions/userAction";

// COMPONENTS

//

const Users = () => {
  const { toggle } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_users());
  }, [dispatch]);

  //
  return (
    <Layout>
      <div id={toggle ? "response" : "responsive"}>
        <AllUser />
      </div>
    </Layout>
  );
};

export default Users;
