import React from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import Layout from './../../common/Layout';
import Createuser from './../../components/user/create-user/Createuser';

//

const CreateUser = () => {
  const { toggle } = useSelector((state) => state.utils);

  //
  return (
    <Layout>

    <div id={toggle ? "response" : "responsive"}>
      <Createuser />
    </div>
    </Layout>
  );
};

export default CreateUser;
