import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AllAgent from '../../components/allagents/AllAgent';
import { get_agents, get_users } from '../../redux/actions/userAction';
import Layout from '../../common/Layout';

// COMPONENTS

//

const Agents = () => {
  const { toggle } = useSelector((state) => state.utils);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_users());
  }, [dispatch]);
  //
  return (
    <Layout>
      <div id={toggle ? 'response' : 'responsive'}>
        <AllAgent />
      </div>
    </Layout>
  );
};

export default Agents;
