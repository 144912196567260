import React, { useState } from 'react';
import Loading from '../alert/Loading';
import {
  deleteDataAPIS,
  patchDataAPI,
  patchDataAPIS,
} from '../../utils/fetchData';
import cogoToast from 'cogo-toast';
import { GLOBALTYPES } from '../../redux/actions/globalTypes';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DeleteModal = ({ token, setDeleteModal, id }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { callback } = useSelector((state) => state.utils);
  const navigate = useNavigate();

  const deleteAccount = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const res = await deleteDataAPIS(`/delete-user/${id}`, token);

      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      setLoading(false);
      navigate('/users/all-agents');
    } catch (error) {
      setLoading(false);
      cogoToast.error(error?.response?.data?.msg, { hideAfter: 5 });
    }
  };

  return (
    <div className='personal-infor'>
      <div className='row'>
        <div className='col-9'>
          <h6>Delete User?</h6>
          <p className='mt-3'>
            User account will be deleted permanently if you proceed!
          </p>
        </div>

        <div className='col-3'>
          <div className='address-cancel float-end'>
            <i
              className='bi bi-x-circle'
              onClick={() => {
                setDeleteModal(false);
              }}
            />
          </div>
        </div>
      </div>

      <hr />

      {/* Profile input section */}
      <div className='form-boxs'>
        <div className='delete-box'>
          <button className='close' onClick={() => setDeleteModal(false)}>
            Close
          </button>

          <button className='delete' onClick={deleteAccount}>
            {loading ? (
              <Loading height='20px' width='20px' color='#fff' />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
