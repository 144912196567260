import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as EmailValidator from 'email-validator';

// COMPONENTS
import Loading from './../../common/alert/Loading';
import { login } from './../../redux/actions/authAction';

// VALIDATION REGEX

const Login = () => {
  const { alert } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          dispatch(login(values, navigate));

          setSubmitting(false);
        }, 500);
      }}
      //   HANDLING VALIDATION MESSAGES
      validate={(values) => {
        let errors = {};

        // EMAIL SECTION
        if (!values.email) {
          errors.email = 'Email is Required';
        } else if (!EmailValidator.validate(values.email)) {
          errors.email = 'Invalid email address';
        }

        //   THE PASSWORD SECTION
        if (!values.password) {
          errors.password = 'Password is Required';
        }

        return errors;
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div className='login'>
            <div className='login-left'></div>

            <div id='overflow' className='login-right'>
              <div className='login-form'>
                <h1>Login to continue</h1>
                <small>Easy way to find a perfect home</small>

                <form onSubmit={handleSubmit}>
                  <div className='form_group'>
                    <label htmlFor='email'>Email</label>
                    <input
                      name='email'
                      type='text'
                      placeholder='bright@example.com'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // className={errors.email && touched.email && 'error'}
                    />
                    {errors.email && touched.email && (
                      <div className='input_feedback'>{errors.email}</div>
                    )}
                  </div>

                  <div className='form_group'>
                    <label htmlFor='password'>Password</label>
                    <input
                      name='password'
                      type='password'
                      placeholder='Enter your password'
                      value={values.password}
                      autoComplete='on'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.password && touched.password && 'error'}
                    />
                    {errors.password && touched.password && (
                      <div className='input_feedback'>{errors.password}</div>
                    )}
                  </div>

                  <div className='form_group'>
                    <button type='submit' disabled={isSubmitting}>
                      {alert.authloading ? (
                        <Loading width='25px' height='25px' color='#fff' />
                      ) : (
                        'Login'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Login;
