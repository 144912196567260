import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import moment from 'moment';
import Loading from '../../common/alert/Loading';
import { useParams } from 'react-router-dom';
import { GLOBALTYPES } from './../../redux/actions/globalTypes';
import cogoToast from 'cogo-toast';
import { getData, patchDataAPI, patchDataAPIS } from '../../utils/fetchData';
import { useNavigate } from 'react-router-dom';
import placeholder from '../../assets/placeholder.png';
import DeclineModal from '../../common/declinemodal/DeclineModal';
import SuspendModal from './../../common/suspendmodal/SuspendModal';
import DeleteModal from './../../common/deletemodal/DeleteModal';

//

const AgentDetail = () => {
  const dispatch = useDispatch();
  const { callback } = useSelector((state) => state.utils);
  const { id } = useParams();
  const [detail, setDetail] = useState([]);
  const [listing, setListing] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [declineModal, setDeclineModal] = useState(false);
  const [suspendModal, setSuspendModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);

    if (id) {
      const getAgentDetails = async () => {
        try {
          setLoading(true);
          const res = await getData(`/agent_details/${id}`);

          setDetail(res.data.agent_details);
          setListing(res.data.agent_listing);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };

      getAgentDetails();
    }
  }, [id, callback]);

  // handleVerify
  const approveAgent = async (e) => {
    e.preventDefault();

    const newData = {
      id: 'new data',
    };

    try {
      setApproveLoading(true);
      const res = await patchDataAPI(`/approve-agent/${id}`, newData, token);

      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      setApproveLoading(false);
    } catch (error) {
      console.log(error);
      cogoToast.error(error?.response?.data?.msg);
      setApproveLoading(false);
    }
  };

  // open decline modal
  const handleDecline = (id) => {
    setDeclineModal(true);
  };

  // Lift suspension
  const liftSuspension = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const newData = {
        id: id,
      };

      const res = await patchDataAPI('/lift-suspension', newData, token);

      cogoToast.success(res.data.msg);
      dispatch({ type: GLOBALTYPES.CALLBACK, payload: !callback });
      setLoading(false);
      setSuspendModal(false);
    } catch (error) {
      setLoading(false);
      cogoToast.error(error?.response?.data?.msg, { hideAfter: 5 });
    }
  };

  if (loading) {
    return (
      <div className='listing-loading'>
        <Loading height='50px' width='50px' />
      </div>
    );
  }

  //
  return (
    <div className='listing-details'>
      <div className='container-fluid'>
        <div className='header-section'>
          <div className='row'>
            <div className='col profile-section'>
              <div className='profile'>
                <img
                  src={detail.image === null ? placeholder : detail.image}
                  alt=''
                />
              </div>

              <div>
                <h1>{detail.fullname}</h1>
                <h2>@{detail.username}</h2>
                <p>{detail.email}</p>
                {detail.isSuspended ? (
                  <small>Account Suspended</small>
                ) : (
                  <small>
                    {detail?.verification[0]?.isVerified === 'true'
                      ? 'Verified'
                      : detail?.verification[0]?.isVerified}
                  </small>
                )}
              </div>
            </div>

            <div className='col-5 verification-status'>
              {detail?.verification?.length !== 0 && (
                <div className='d-flex align-items-center justify-content-end'>
                  {detail?.verification[0]?.isVerified !== 'true' &&
                    detail?.verification[0]?.isVerified !== 'declined' && (
                      <button className='approve' onClick={approveAgent}>
                        {approveLoading ? 'verifying' : 'Verify'}
                      </button>
                    )}

                  {detail?.verification[0]?.isVerified !== 'true' &&
                    detail?.verification[0]?.isVerified !== 'declined' && (
                      <button className='decline' onClick={handleDecline}>
                        Decline
                      </button>
                    )}

                  {/* <button className='decline' onClick={handleDecline}>
                    Decline
                  </button> */}

                  {detail.isSuspended ? (
                    <button
                      className='remove-from-suspension'
                      onClick={liftSuspension}
                    >
                      Lift suspension
                    </button>
                  ) : (
                    <button
                      className='decline'
                      onClick={() => setSuspendModal(true)}
                    >
                      Suspend
                    </button>
                  )}

                  {detail?.verification[0]?.isVerified === 'declined' && (
                    <button className='approve' onClick={approveAgent}>
                      Re-Approve
                    </button>
                  )}

                  <button
                    onClick={() => setDeleteModal(true)}
                    className='delete'
                  >
                    Delete user
                  </button>
                </div>
              )}

              <div className='d-flex align-items-end justify-content-end h-100'>
                <p>
                  Joined at : {moment(detail?.updatedAt).format('MMMM Do YYYY')}{' '}
                </p>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className='middle-section'>
            <h3>Property Information</h3>

            <div className='row'>
              <div className='col'>
                <label htmlFor=''>Identity name</label>
                <input
                  type='text'
                  className='form-control'
                  aria-label='property_type'
                  value={
                    detail?.verification?.length !== 0
                      ? detail?.verification[0]?.identity_name
                      : ''
                  }
                  readOnly={true}
                />
              </div>

              <div className='col'>
                <label htmlFor=''>Identity mobile</label>
                <input
                  type='text'
                  className='form-control'
                  aria-label='price'
                  value={
                    detail?.verification[0]?.identity_mobile
                      ? detail?.verification[0]?.identity_mobile
                      : ''
                  }
                  readOnly
                />
              </div>

              <div className='col'>
                <label htmlFor=''>Document type</label>
                <input
                  type='text'
                  className='form-control'
                  aria-label='state_name'
                  value={
                    detail?.verification[0]?.document_type
                      ? detail?.verification[0]?.document_type
                      : ''
                  }
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className='image-section'>
            <div className='image-card'>
              <img
                src={
                  detail?.verification[0]?.identity_selfie
                    ? detail?.verification[0]?.identity_selfie
                    : ''
                }
                alt=''
              />
            </div>

            <div className='identity-card'>
              <img
                src={
                  detail?.verification[0]?.identity_document
                    ? detail?.verification[0]?.identity_document
                    : ''
                }
                alt=''
              />
            </div>
          </div>

          <div className='listing-section'>
            <div className='listing-table'>
              {listing.length === 0 ? (
                <p className='text-center mt-4'>No Listings found</p>
              ) : (
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col'>No.</th>
                      <th scope='col'>Image</th>
                      <th scope='col'>Property type</th>
                      <th scope='col'>Price</th>
                      <th scope='col'>City</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>Date</th>
                      <th scope='col'>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listing.map((item, index) => {
                      const {
                        _id,
                        images,
                        property_type,
                        status,
                        price,
                        city,
                        createdAt,
                      } = item;

                      return (
                        <tr key={_id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className='table-image'>
                              <img src={images[0].url} alt='listing-pic' />
                            </div>
                          </td>
                          <td>{property_type}</td>
                          <td>₦ {price}</td>
                          <td>{city}</td>
                          <td>{status}</td>
                          <td>{moment(createdAt).format('MMMM Do YYYY')}</td>
                          <td>
                            <div className='table-actions'>
                              <button
                                className='view'
                                onClick={() =>
                                  navigate(`/listings/listingdetails/${_id}`)
                                }
                              >
                                View
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </>
      </div>

      {/* decline modal */}
      <Modal
        show={declineModal}
        onHide={() => {
          setDeclineModal(false);
        }}
        dialogClassName='decline-modal'
      >
        <DeclineModal token={token} setDeclineModal={setDeclineModal} />
      </Modal>

      {/* suspend modal */}
      <Modal
        show={suspendModal}
        onHide={() => {
          setSuspendModal(false);
        }}
        dialogClassName='suspend-modal'
      >
        <SuspendModal token={token} setSuspendModal={setSuspendModal} id={id} />
      </Modal>

      {/* delete user modal */}
      <Modal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        dialogClassName='suspend-modal'
      >
        <DeleteModal token={token} setDeleteModal={setDeleteModal} id={id} />
      </Modal>
    </div>
  );
};

export default AgentDetail;
