import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

//
import Loading from '../../common/alert/Loading';
import Goback from '../../common/goback/Goback';
import Filter from '../../common/filter/Filter';
import Pagination from '../../common/filter/Pagination';
import { filteringMethod } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import Heading from './../../common/Heading';

//

const ReportedListing = () => {
  const { alert } = useSelector((state) => state);
  const { all_listings } = useSelector((state) => state.listing);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState('');
  const [values, setValues] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  let sorted = filteringMethod(all_listings, data, values);

  // display only reported listings
  const reportedData = sorted.filter((item) => item.reportedBy.length !== 0);

  // Pagination function
  let page;
  let currentData = useMemo(() => {
    let firstPageIndex = (currentPage - 1) * pageSize;
    let lastPageIndex = firstPageIndex + pageSize;
    /* eslint-disable */
    page = firstPageIndex;
    return reportedData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, reportedData]);

  //
  const count = page + currentData.length;

  if (alert.listingloading) {
    return (
      <div className='listing-loading'>
        <Loading width='45px' height='45px' color='#A1257D' />
      </div>
    );
  }

  //
  return (
    <div className='listing'>
      <Heading title='Reported Listings' />
      {/* display the filter component if current data is not null */}
      {currentData !== null && (
        <Filter
          data={data}
          setData={setData}
          values={values}
          setValues={setValues}
        />
      )}

      <div className='listing-table'>
        {/* display showing the length of items if current data is not null */}
        {currentData !== null && (
          <small className='showing'>
            Showing <b>{count}</b> listings of <b>{currentData?.length}</b>{' '}
            total
          </small>
        )}

        {currentData === null ? (
          <p className='text-center mt-4'>No Listings found</p>
        ) : (
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>No.</th>
                <th scope='col' style={{ width: '7%' }}>
                  Image
                </th>
                <th scope='col'>Property type</th>
                <th scope='col'>Price</th>
                <th scope='col'>Reported</th>
                <th scope='col'>Status</th>
                <th scope='col'>Date</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>

            <tbody>
              {currentData.map((item, index) => {
                const {
                  _id,
                  images,
                  property_type,
                  status,
                  price,
                  city,
                  createdAt,
                  reportedBy,
                } = item;

                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>
                      <div className='table-image'>
                        <img src={images[0].url} alt='listing-pic' />
                      </div>
                    </td>
                    <td>{property_type}</td>
                    <td>₦ {price}</td>
                    <td>{reportedBy.length}</td>
                    <td>{status}</td>
                    <td>{moment(createdAt).format('MMMM Do YYYY')}</td>
                    <td>
                      <div className='table-actions'>
                        <button
                          className='view'
                          onClick={() =>
                            navigate(`/listings/reportedlistingdetails/${_id}`)
                          }
                        >
                          View
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      {currentData !== null && !alert.listingloading && (
        <Pagination
          filteringData={reportedData}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default ReportedListing;
