import React, { useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//
import { GLOBALTYPES } from './../../redux/actions/globalTypes';
import fav from '../../assets/fav.png';
import logo from '../../assets/logo2.png';
//

const Sidebar = (props) => {
  const { toggle } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (toggle) {
      removeActiveClassFromSubMenu();
    }

    // dispatch
  }, [props, toggle]);

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll('.sub-menu').forEach((el) => {
      el.classList.remove('active');
    });
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach((el) => {
      el.addEventListener('click', (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove('active'));
        el.classList.toggle('active');
        // console.log(next);
        if (next !== null) {
          next.classList.toggle('active');
        }
      });
    });
  }, []);

  // The toggle method
  const handleToggle = () => {
    dispatch({ type: GLOBALTYPES.TOGGLE, payload: !toggle });
  };

  // logout
  const logout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <>
      <div className={`side-menu ${toggle ? 'inactive' : ''}`}>
        {/* THE TOP SECTION */}
        <div className='top-section'>
          {toggle ? (
            <div className='toggle-logo'>
              <img src={fav} alt='logo' />
            </div>
          ) : (
            <div className='logo'>
              <img src={logo} alt='logo' />
            </div>
          )}
        </div>

        {/* THE MAIN MENU SECTION  */}
        <div className='main-menu'>
          <ul>
            <li>
              <div
                className={`menu-item ${
                  pathname.includes('overview') ? 'active' : ''
                }`}
              >
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-speedometer2'></i>
                </div>
                <span>Dashboard</span>
              </div>

              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/dashboard/overview'>Overview</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <div
                className={`menu-item ${
                  pathname.includes('listings') ? 'active' : ''
                }`}
              >
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-bag'></i>
                </div>
                <span>Listings</span>
              </div>

              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/listings/create-categories'>
                    Create Categories
                  </NavLink>
                  <NavLink to='/listings/all-categories'>
                    All Categories
                  </NavLink>
                  <NavLink to='/listings/create-listings'>
                    Create Listings
                  </NavLink>
                  <NavLink to='/listings/all-listings'>All Listings</NavLink>
                  <NavLink to='/listings/reported-listings'>
                    Reported Listings
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <div
                className={`menu-item ${
                  pathname.includes('users') ? 'active' : ''
                }`}
              >
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-people-fill'></i>
                </div>
                <span>Users</span>
              </div>
              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/users/create-user'>Create Admin</NavLink>
                  <NavLink to='/users/all-users'>All Users</NavLink>
                  <NavLink to='/users/all-agents'>All Agents</NavLink>
                  <NavLink to='/users/all-agents'>All Admins</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <div
                className={`menu-item ${
                  pathname.includes('advertise') ? 'active' : ''
                }`}
              >
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-badge-ad'></i>
                </div>
                <span>Advertise</span>
              </div>
              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/advertise/create-advert'>Create Advert</NavLink>
                  <NavLink to='/advertise/all-adverts'>All Adverts</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <div className='menu-item'>
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-patch-question'></i>
                </div>
                <span>Faqs</span>
              </div>

              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/faqs/create-faqs'>Create Faqs</NavLink>
                  <NavLink to='/faqs/all-faqa'>All Faqs</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <div
                className={`menu-item ${
                  pathname.includes('contact') ? 'active' : ''
                }`}
              >
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-info-circle'></i>
                </div>
                <span>Help Center</span>
              </div>
              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/contact/all_contact'>Contacts</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <div className='menu-item'>
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-newspaper'></i>
                </div>
                <span>Newsletter</span>
              </div>
              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/complain/view_complain'>View Users</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <div className='menu-item'>
                <div
                  className={toggle ? 'menu-icon menu-icons' : 'menu-icon'}
                  onClick={handleToggle}
                >
                  <i className='bi bi-gear'></i>
                </div>
                <span>Settings</span>
              </div>
              <ul className={`sub-menu`}>
                <li>
                  <NavLink to='/settings/preference'>Make Complain</NavLink>
                  <NavLink to='/settings/profile'>View Complain</NavLink>
                  <span onClick={logout} className='a'>
                    Logout
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
