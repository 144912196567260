import { GLOBALTYPES } from "../actions/globalTypes";

const initialState = {
  all_listings: [],
};

const listingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBALTYPES.ALL_LISTINGS:
      return {
        ...state,
        all_listings: payload,
      };

    default:
      return state;
  }
};

export default listingReducer;
