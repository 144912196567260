import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Loading from '../../common/alert/Loading';
import Filter from '../../common/filter/Filter';
import Pagination from '../../common/filter/Pagination';
import { filteringMethod } from '../../utils/utils';
import placeholder from '../../assets/placeholder.png';

const AllUser = () => {
  const { alert } = useSelector((state) => state);
  const { all_users } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState('');
  const [values, setValues] = useState('');
  const [pageSize, setPageSize] = useState(10);

  let sorted = filteringMethod(all_users, data, values);

  // Pagination function
  let page;
  let currentData = useMemo(() => {
    let firstPageIndex = (currentPage - 1) * pageSize;
    let lastPageIndex = firstPageIndex + pageSize;
    /* eslint-disable */
    page = firstPageIndex;
    return sorted.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, sorted]);

  //
  const count = page + currentData.length;

  if (alert.userloading) {
    return (
      <div className='listing-loading'>
        <Loading width='50px' height='50px' color='#A1257D' />
      </div>
    );
  }

  //
  return (
    <div className='user'>
      {/* display the filter component if current data is not null */}
      {currentData !== null && (
        <Filter
          data={data}
          setData={setData}
          values={values}
          setValues={setValues}
        />
      )}

      {alert.productloading ? (
        <div className='user-loading'>
          <Loading width='45px' height='45px' color='#A1257D' />
        </div>
      ) : (
        <div className='user-table'>
          {/* display showing the length of items if current data is not null */}
          {currentData !== null && (
            <small className='showing'>
              Showing <b>{count}</b> users of <b>{all_users?.length}</b> total
            </small>
          )}

          {currentData === null ? (
            <p className='text-center mt-4'>No user found</p>
          ) : (
            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'>No.</th>
                  <th scope='col' style={{ width: '7%' }}>
                    Image
                  </th>
                  <th scope='col'>Fullname</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Username</th>
                  <th scope='col'>UserType</th>
                  <th scope='col'>Joined</th>
                  <th scope='col'>Actions</th>
                </tr>
              </thead>

              <tbody>
                {currentData.map((item, index) => {
                  const {
                    _id,
                    image,
                    fullname,
                    email,
                    username,
                    userType,
                    createdAt,
                  } = item;

                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>
                        <div className='table-image'>
                          <img
                            src={!image ? placeholder : image}
                            alt='user-pic'
                          />
                        </div>
                      </td>
                      <td>{fullname}</td>
                      <td>{email}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {username}
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {userType}
                      </td>
                      <td>{moment(createdAt).format('MMMM Do YYYY')}</td>
                      <td>
                        <div className='table-actions'>
                          <button className='delete'>Suspend</button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}

      {currentData !== null && (
        <Pagination
          filteringData={all_users}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default AllUser;
